<template>
  <div class="admin-partners">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/integration')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Integration - Partners
      </h1>
      <div class="actions">
           <a-button
          @click.prevent="load"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="addPartner"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Add</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="api-clients-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No partners to show -->
      <a-alert
        v-if="!isLoading && partners.length == 0"
        type="info"
        message="No partners to show"
      >
      </a-alert>
      <!-- / No partners to show -->

      <!-- Loaded -->
      <div class="partners-list" v-if="!isLoading && partners.length">
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="partner in partners"
            :key="partner.id"
          >
          <api-client-list-item @selected="$router.push('/admin/integration/partners/' + partner.id)"
           :tenant-id="tenantId" :api-client="getApiClientForPartner(partner)"></api-client-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiClientListItem from './ApiClients/ApiClientListItem.vue';
const _ = require('lodash');
export default {
  components: { ApiClientListItem },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.load();
  },

  watch: {
    selectedOrganisation() {
      this.load();
    },
  },

  methods: {
    ...mapActions("adminApiClients", {
      loadApiClients: "loadApiClients",
      loadApiGrants: "loadApiGrants",
      loadApiGrantPermissionOptions: "loadApiGrantPermissionOptions"
    }),

    load() {
      this.loadApiClients();
      this.loadApiGrants();
      this.loadApiGrantPermissionOptions();
    },

    addPartner() {
      this.$router.push("/admin/integration/partners/create");
    },

    getApiClientForPartner(apiClient) {
      return _.find(this.apiClients, {id: apiClient.apiClientId});
    }
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId"
    }),

    ...mapGetters("adminApiClients", {
      isLoading: "isLoading",
      partners: "partners",
      apiClients: "apiClients"
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },
  },
};
</script>

<style scoped lang="scss">
.api-client-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}
</style>